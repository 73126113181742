angular.module('app.controllers', []).controller('EmptyCtrl', ['$scope', '$location'].append(function(self, $location) {}));

angular.module('app.controllers').controller('GalleryViewCtrl', ['$scope', '$rootScope', '$routeParams', 'APIGallery', '$http', '$location'].append(function(self, root, $routeParams, APIGallery, $http, $location) {
  self.id = $routeParams.id || null;
  APIGallery.query({
    gallery_id: self.id
  }, function(data) {
    return self.gallery = data;
  });
}));
