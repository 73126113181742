angular.module('app.controllers', [])
  .controller 'EmptyCtrl', ['$scope', '$location'].append (self, $location) -> 
    return

angular.module('app.controllers').controller 'GalleryViewCtrl', [
  '$scope', '$rootScope', '$routeParams', 'APIGallery', '$http', '$location'].append (self, root, $routeParams, APIGallery, $http, $location) ->
      
  self.id = $routeParams.id || null

  APIGallery.query {gallery_id: self.id}, (data) ->
    self.gallery = data

  return